<script>
import AppCard from '@/components/card/Card.vue'

export default {
  props: {
    userLogonMasterProp: Object
  },
  components: {
    AppCard
  },
  data() {
    return {
      groups: []
    }
  },
  watch: {
    userLogonMasterProp() {
      this.setGroups()
    }
  },
  created() {
    this.setGroups()
  },
  methods: {
    setGroups() {
      if (this.userLogonMasterProp) {
        this.groups = [...this.userLogonMasterProp.groups]
      }
    }
  }
}
</script>

<template>
  <div class="p-2 h-100">
    <app-card class="h-100">
      <b-row>
        <b-col>
          <span class="text-lg font-weight-bold">
            กลุ่มผู้ใช้งาน
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col v-if="groups.length > 0">
          <b-badge
            class="py-2 px-3 mr-2 mb-2"
            variant="primary"
            v-for="(group, index) in groups"
            :key="index"
          >
            {{ group.groupName }}
          </b-badge>
        </b-col>
        <b-col v-else>
          <span class="text-md">ไม่อยู่ในกลุ่มผู้ใช้งานกลุ่มใด ๆ</span>
        </b-col>
      </b-row>
    </app-card>
  </div>
</template>

<style></style>
