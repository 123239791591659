<script>
import { toastMixins, datetimeMixins } from '@/mixins'

import userService from '@/services/user/user'

import MenuBar from './components/MenuBar.vue'
import InformationPanel from './components/InformationPanel.vue'
import GroupPanel from './components/GroupPanel.vue'
import PermissionPanel from './components/PermissionPanel.vue'

export default {
  mixins: [toastMixins, datetimeMixins],
  components: {
    MenuBar,
    InformationPanel,
    GroupPanel,
    PermissionPanel
  },
  data() {
    return {
      userId: null,
      userLogonMaster: null,
      errMsg: null
    }
  },
  created() {
    this.userId = this.$route.params.userId
    this.fetchUserLogonMasterDetail()
  },
  methods: {
    async fetchUserLogonMasterDetail() {
      try {
        const res = await userService.getUserLogonMaster(this.userId)
        this.userLogonMaster = this.formatResult(res.data.data)
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
    },
    formatResult(userLogonMaster) {
      const {
        lastLoggedOnAt,
        lastPasswordChangedAt,
        passwordExpiredAt,
        userExpiredAt,
        createdAt,
        updatedAt
      } = userLogonMaster
      return {
        ...userLogonMaster,
        lastLoggedOnAtFormat:
          this.mxConvertToDatetimeBuddhistFormat(lastLoggedOnAt),
        lastPasswordChangedAtFormat: this.mxConvertToDatetimeBuddhistFormat(
          lastPasswordChangedAt
        ),
        passwordExpiredAtFormat:
          this.mxConvertToDatetimeBuddhistFormat(passwordExpiredAt),
        userExpiredAtFormat:
          this.mxConvertToDatetimeBuddhistFormat(userExpiredAt),
        createdAtFormat: this.mxConvertToDatetimeBuddhistFormat(createdAt),
        updatedAtFormat: this.mxConvertToDatetimeBuddhistFormat(updatedAt)
      }
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="รายละเอียดผู้ใช้งาน"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar :userLogonMasterProp="userLogonMaster"></menu-bar>
      </b-col>
    </b-row>

    <b-row>
      <!-- information -->
      <b-col cols="12" lg="6">
        <information-panel
          :userLogonMasterProp="userLogonMaster"
        ></information-panel>
      </b-col>

      <!-- group -->
      <b-col cols="12" lg="6">
        <group-panel :userLogonMasterProp="userLogonMaster"></group-panel>
      </b-col>

      <!-- permission -->
      <b-col cols="12">
        <permission-panel
          :userLogonMasterProp="userLogonMaster"
        ></permission-panel>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
