<script>
import AppCard from '@/components/card/Card.vue'

export default {
  props: {
    userLogonMasterProp: Object
  },
  components: {
    AppCard
  },
  data() {
    return {
      fields: [
        { key: 'userId', label: 'ผู้ใช้งาน' },
        { key: 'userName', label: 'ชื่อผู้ใช้งาน' },
        { key: 'userLevel', label: 'ระดับผู้ใช้งาน' },
        { key: 'department', label: 'แผนก' },
        { key: 'jobTitle', label: 'ตำแหน่งงาน' },
        { key: 'lastLoggedOnAtFormat', label: 'เข้าสู่ระบบล่าสุด' },
        { key: 'lastPasswordChangedAtFormat', label: 'แก้ไขรหัสผ่านล่าสุด' },
        { key: 'passwordExpiredAtFormat', label: 'รหัสผ่านหมดอายุ' },
        { key: 'userExpiredAtFormat', label: 'ผู้ใช้งานหมดอายุ' },
        { key: 'entryBy', label: 'แก้ไขล่าสุดโดย' },
        { key: 'createdAtFormat', label: 'สร้างเมื่อ' },
        { key: 'updatedAtFormat', label: 'แก้ไขล่าสุดเมื่อ' }
      ]
    }
  }
}
</script>

<template>
  <div class="p-2 h-100">
    <app-card class="h-100">
      <b-row>
        <b-col>
          <span class="text-lg font-weight-bold">
            ข้อมูลรายละเอียด
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-row v-for="field in fields" :key="field.key" class="mt-2">
            <b-col cols="4">
              <span>
                {{ field.label }}
              </span>
            </b-col>
            <b-col cols="8"
              ><span>
                {{
                  (userLogonMasterProp && userLogonMasterProp[field.key]) || '-'
                }}
              </span></b-col
            >
          </b-row>
        </b-col>
      </b-row>
    </app-card>
  </div>
</template>

<style></style>
