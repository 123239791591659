<script>
import userService from '@/services/user/user'

export default {
  props: {
    userLogonMasterProp: Object
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    getTargetUserInfo() {
      if (this.userLogonMasterProp) {
        const {
          userId,
          userName,
          userLevel,
          department,
          jobTitle
        } = this.userLogonMasterProp
        return [
          { key: 'userId', value: userId || '-', label: 'รหัส' },
          { key: 'userName', value: userName || '-', label: 'ชื่อ' },
          { key: 'userLevel', value: userLevel || '-', label: 'ระดับ' },
          { key: 'department', value: department || '-', label: 'แผนก' },
          { key: 'jobTitle', value: jobTitle || '-', label: 'ตำแหน่งงาน' }
        ]
      }
      return []
    }
  },
  methods: {
    buttonClicked(type) {
      switch (type) {
        case 'CONFIRM':
          if (this.userLogonMasterProp) {
            this.deleteUserLogonMaster(this.userLogonMasterProp.userId)
          }
          break
        case 'CANCEL':
          this.$emit('closeModal', {
            type: 'CLOSE'
          })
          break
        default:
          break
      }
    },
    async deleteUserLogonMaster(userId) {
      this.isLoading = true
      try {
        await userService.deleteUserLogonMaster(userId)
        this.$emit('closeModal', {
          type: 'CLOSE_WITH_SUCCESS',
          message: 'ลบผู้ใช้งานสำเร็จ'
        })
      } catch (err) {
        this.$emit('closeModal', {
          type: 'DISPLAY_ERROR',
          message: err.response.data.thMessage
        })
      }
      this.isLoading = false
    }
  }
}
</script>

<template>
  <div v-if="userLogonMasterProp">
    <b-row align-v="start">
      <!-- trash icon -->
      <b-col class="col-3">
        <b-icon
          icon="trash-fill"
          variant="danger"
          class="mt-2 icon-style"
        ></b-icon>
      </b-col>

      <!-- content -->
      <b-col class="col-9">
        <h5>รายละเอียดของผู้ใช้งานที่จะลบ</h5>
        <div
          class="d-flex mt-3"
          v-for="info in getTargetUserInfo"
          :key="info.key"
        >
          <span> {{ info.label }}: </span>
          <span class="ml-2 font-weight-bold">
            {{ info.value }}
          </span>
        </div>
      </b-col>
    </b-row>

    <!-- hover line -->
    <hr />

    <!-- buttons -->
    <b-row align-h="end">
      <b-col class="col-3">
        <b-button
          class="w-100"
          variant="danger"
          @click="buttonClicked('CONFIRM')"
          :disabled="isLoading"
        >
          <b-spinner v-if="isLoading" variant="light" small></b-spinner>
          <span v-else>ลบ</span>
        </b-button>
      </b-col>
      <b-col class="col-3">
        <b-button
          class="w-100"
          variant="outline-danger"
          @click="buttonClicked('CANCEL')"
          :disabled="isLoading"
        >
          ยกเลิก
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
.icon-style {
  width: 100%;
  height: 100%;
}
</style>
